/**
 * This is the ABMobileApp subclass for the Financial ABApplication
 */
"use strict";

//import the applications
import Common from "../classes/Common.js";

// DC
const DC_EXPENSE_REPORT_UUID = "7ca8bcb4-b078-4dc9-9091-1e6000230bf4"; // "Report Items Tab";
const DC_RECEIPT_UUID = "aae44fe2-e80b-48d2-8adb-1c6ba74acc65"; // "Receipt Photos by User";
const DC_REPORT_ITEM_UUID = "989c8de3-d890-460e-a63f-a4d77a1f9e82"; // "Report Items Tab";

const dcIDs = [
   "My Team RCs", // ! Missing
   "Project", // GIGANTIC
   "Moderators - mobile",
   "QX Center - Mobile", /// ! ABSCENT
   "Cash Account", // data:[Object, object] Why
   "Team",
   "IE Categories - Mobile", /// !
   "Expense Report - Mobile",
   "Receipt Photos by User",
   "Report Items Tab",
   "User Person",
   "powerUserRCs",
   "powerUserPRJs",
];

const numberPattern = /\d+/g;

class Financial extends Common {
   constructor() {
      super(
         [
            {
               path: "/financial/:type/",
               routes: [
                  {
                     path: "expenses/",
                     routes: [
                        {
                           path: "reports/",
                           componentUrl:
                              "./lib/applications/financial/templates/financial-expense-reports.html",
                           routes: [
                              {
                                 path: "add/",
                                 componentUrl:
                                    "./lib/applications/financial/templates/financial-expense-report-add.html",
                              },
                              {
                                 path: "edit/:id/",
                                 componentUrl:
                                    "./lib/applications/financial/templates/financial-expense-report-edit.html",
                              },
                           ],
                        },
                        {
                           path: "items/",
                           componentUrl:
                              "./lib/applications/financial/templates/financial-expense-items.html",
                           routes: [
                              {
                                 path: "add/",
                                 componentUrl:
                                    "./lib/applications/financial/templates/financial-expense-item-add.html",
                              },
                              {
                                 path: "edit/:id/",
                                 componentUrl:
                                    "./lib/applications/financial/templates/financial-expense-item-edit.html",
                                 routes: [
                                    {
                                       path: "receipt/",
                                       panel: {
                                          componentUrl:
                                             "./lib/applications/financial/templates/financial-expense-receipt.html",
                                       },
                                    },
                                 ],
                              },
                           ],
                        },
                     ],
                  },
               ],
            },
         ],
         [
            {
               path: "/financial/:type/",
               componentUrl:
                  "./lib/applications/financial/templates/financial-nav.html",
            },
         ],
         {
            wildcard: true,
         },
      );

      this._dcKeys = {
         expenseReport: DC_EXPENSE_REPORT_UUID,
         receipt: DC_RECEIPT_UUID,
         reportItems: DC_REPORT_ITEM_UUID,
      };
      this.id = "FINANCIAL";
   }

   // Depending on user type, these data collections may be different
   RC_DC(isPowerUser) {
      return isPowerUser ? "powerUserRCs" : "My Team RCs";
   }
   PRJ_DC(isPowerUser) {
      return isPowerUser ? "powerUserPRJs" : "Project";
   }
   ITEM_DC(isPowerUser) {
      return "Report Items Tab";
      // TODO
      return isPowerUser ? "powerUserItems" : "Report Items Tab";
   }
   REPORT_DC(isPowerUser) {
      return "Expense Report - Mobile";
      //TODO
      return isPowerUser ? "powerUserReports" : "Expense Report - Mobile";
   }

   categoryFilter(isPersonal) {
      if (isPersonal) {
         // console.log({
         //    "filtering list by": isPersonal,
         //    "keyword is": "Personal",
         // });
         return "Personal";
      } else {
         // console.log({
         //    "filtering list by": isPersonal,
         //    "keyword is": "Ministry",
         // });
         return "Ministry";
      }
   }

   /**
    * isValidModerator()
    * test if string matches a valid value in moderator object.
    * parameters: string
    * returns: boolean
    */
   moderatorSearch(approverRequest) {
      // Check whether the data collection is initialized
      if (this.dataCollection("Moderators - mobile").dataStatus === 2) {
         // user input should be case insensitive.
         approverRequest = approverRequest.toUpperCase();
         var moderatorObj = this.dataCollection("Moderators - mobile").getData(
            (c) => {
               const data = c.data ?? c;
               let user = data.User ? data.User.toUpperCase() : "";
               let nickname = data.Nickname ? data.Nickname.toUpperCase() : "";
               let nameSearch =
                  user === approverRequest || nickname === approverRequest;
               return nameSearch && data.status == "Open Inbox";
            },
         );
         if (moderatorObj.length == 0) {
            return { found: false };
         } else {
            const moderatorObjData = moderatorObj[0].data || moderatorObj[0];
            return {
               name: moderatorObjData.Nickname || moderatorObjData.User,
               found: true,
               object: moderatorObjData,
            };
         }
      } else {
         // the data collection is not initialized
         // show error message, telling user to please wait as moderators are being loaded
         this.page.f7App.dialog.alert(
            "Please wait, moderators are being loaded. Try again in a few seconds.",
         );
         return { found: false };
      }
   }

   /**
    * @flatData()
    *
    * to prevent circular json references, we will prevent the
    * __relation fields in our data.
    * NOTE: this is only for already embedded entries that can
    * cause circular references:
    * eg:  Expense Item => Receipt => flatData(ExpenseItem)
    */
   flatData(item) {
      if (!item || typeof item !== "object") {
         console.warn("financial.flatData() item is not an object:", item);
         return item;
      }
      let newItem;
      if (Array.isArray(item)) {
         newItem = [];
         item.forEach((i) => {
            newItem.push(this.flatData(i));
         });
      } else {
         newItem = {};
         Object.keys(item).forEach((k) => {
            try {
               if (k.indexOf("__relation") == -1) {
                  newItem[k] = item[k];
               }
            } catch (err) {
               console.error("financial.flatData(): flatData __relation", err);
            }
         });
      }

      return newItem;
   }

   // date, Expenses 2, Title, Status
   // 'date'

   /**
    * Retrieves and formats expense reports based on the provided parameters.
    *
    * @param {boolean} isPersonal - Indicates whether the expense reports are personal or not.
    * @param {Date} firstDay - The first day of the date range to filter the expense reports.
    * @param {Date} lastDay - The last day of the date range to filter the expense reports.
    * @param {Object} [newValue={}] - an record which may or may not exist in the data collection
    * @returns {Object} - The formatted expense reports object.
    */
   getFormattedExpenseReports(isPersonal, firstDay, lastDay) {
      switch ($(document.body).attr("lang")) {
         case "zh":
            moment.locale("zh-cn");
            break;
         default:
            moment.locale("en");
            break;
      }
      const categoryFilter = this.categoryFilter(isPersonal);
      const expenseReports = this.dataCollection(
         "Expense Report - Mobile",
      ).getData((e) => {
         const data = e.data;
         let expDate = data.date;
         if (expDate.indexOf("T") > -1) expDate = expDate.split("T")[0];
         expDate = moment(expDate);
         return (
            expDate.isSameOrBefore(moment(lastDay).endOf("day")) &&
            expDate.isSameOrAfter(moment(firstDay).startOf("day")) &&
            (data.Category == undefined || data.Category == categoryFilter)
         );
      });

      // don't bother if we don't have data
      if (expenseReports.length === 0) {
         console.log("we don't have Expense Report - Mobile data");
         return { dates: [], total: 0 };
      }
      const reportItemDC = this.dataCollection(this._dcKeys.reportItems);
      const expenseHash = expenseReports.map((e) => {
         const data = e.data;
         return {
            date: moment(data.date).format("YYYYMMDD"),
            isConfirmed: e.isConfirmed,
            total: (
               (!e.isConfirmed && data.ReportItem686__relation) ||
               reportItemDC
                  .getData(
                     (reportItem) =>
                        (reportItem.data.Report ||
                           reportItem.data.Report__relation?.id) === e.id,
                  )
                  .map((reportItem) => reportItem.data)
            ).reduce((a, b) => a + (b.Amount ?? 0), 0),
            data,
         };
      });

      // passed firstDay is the first day of the current month reverse the expense order
      const date = new Date();
      if (
         firstDay.getTime() ===
         new Date(date.getFullYear(), date.getMonth(), 1).getTime()
      )
         return {
            dates: Object.entries(
               _.mapValues(_.groupBy(expenseHash, "date"), (clist) =>
                  clist.map((car) => _.omit(car, "date")),
               ),
            )
               .map(([key, values]) => ({
                  expenseReports: values, // this is what the sub report iterates through! very important
                  date: {
                     day: moment(key, "YYYYMMDD").format("D"),
                     month: moment(key, "YYYYMMDD").format("MMM"),
                  },
               }))
               .reverse(),
            total: expenseHash
               .reduce((a, b) => a + (b.total ?? 0), 0)
               .toFixed(2),
         };

      // dates is a special array of all used dates to be used in timeline
      // Foreach Date
      // // each report
      return {
         dates: Object.entries(
            _.mapValues(_.groupBy(expenseHash, "date"), (clist) =>
               clist.map((car) => _.omit(car, "date")),
            ),
         ).map(([key, values]) => ({
            expenseReports: values, // this is what the sub report iterates through! very important
            date: {
               day: moment(key, "YYYYMMDD").format("D"),
               month: moment(key, "YYYYMMDD").format("MMM"),
            },
         })),
         total: expenseHash.reduce((a, b) => a + (b.total ?? 0), 0).toFixed(2),
      };
   }

   // if report is defined, get related items.
   // if it is blank, get un-set report items
   getFormattedReportItems(isPersonal, firstDay, lastDay, expenseReport) {
      var langCode = $(document.body).attr("lang");
      if (langCode == "zh") {
         moment.locale("zh-cn");
      } else {
         moment.locale("en");
      }

      var categoryFilter = this.categoryFilter(isPersonal);
      // console.log({ "filtering list by": categoryFilter });

      var expenseHash = [];
      var expensesObj = { dates: [], total: 0 };
      var expenseItems;
      var expensesFormatted = [];
      var startDate;
      var endDate;
      var dateFilter = false;
      var groupByDates = true;
      if (firstDay && lastDay) {
         dateFilter = true;
         startDate = moment(firstDay);
         endDate = moment(lastDay);
      }

      // this is the default filter
      var checker = function (i) {
         const data = i.data;
         return (
            !data.Report &&
            (data.Category == categoryFilter || data.Category == undefined)
         );
      };
      // if we pass a report and we want to filter by it
      if (typeof expenseReport != "undefined") {
         if (dateFilter) {
            checker = function (i) {
               const data = i.data;
               let expDate = moment(data["Date"]);
               return (
                  expDate.isSameOrBefore(endDate.endOf("day")) &&
                  expDate.isSameOrAfter(startDate.startOf("day")) &&
                  data["Report"] == expenseReport &&
                  data.Category == categoryFilter
               );
            };
         } else {
            checker = function (i) {
               const data = i.data;
               return (
                  data["Report"] == expenseReport &&
                  (data.Category == categoryFilter ||
                     data.Category == undefined)
               );
            };
            groupByDates = false;
         }
      } else {
         if (dateFilter) {
            // if we don't have filter get all depending on dates only
            checker = function (i) {
               const data = i.data;
               let expDate = moment(data["Date"]);
               return (
                  expDate.isSameOrBefore(endDate.endOf("day")) &&
                  expDate.isSameOrAfter(startDate.startOf("day")) &&
                  (data.Category == categoryFilter ||
                     data.Category == undefined)
               );
            };
         } else {
            // if we don't have any filters, will use default filter
            groupByDates = false;
         }
      }
      // filter by the checker we just set
      expenseItems = this.dataCollection("Report Items Tab").getData((e) =>
         checker(e),
      );

      // console.log({ "the items after filtering were": expenseItems });
      // If ["Item ID"] is not set, these don't exist on the server
      // are there any report items?
      if (expenseItems.length == 0) {
         if (groupByDates) {
            // console.log({ "do have have expense item data collection?": this.dataCollection("Report Items Tab") });
            expensesObj = {
               dates: expenseItems,
               // total: totalExpenses.toFixed(2),
               symbol: "",
            };
            // console.log({ "we don't have expense item data": expensesObj });
            return expensesObj;
         } else {
            // console.log("we don't have expense item data");
            return expenseItems;
         }
      }

      // fill out data of each item
      // get status, get date, get ReceiptsConnections
      const receiptDC = this.dataCollection(this._dcKeys.receipt);
      expenseItems.forEach((i) => {
         const data = i.data;
         // default to falsy, fill in if connected report
         data.isEditable = false;
         data.status = 0;
         if (
            data["Report__relation"] == null ||
            data["Report__relation"]["Status"] == null
         ) {
            data.status = "Rejected"; // make it red to tell user it is not connected to a report
            data.isEditable = true;
         } else {
            // i.status = this.expenseReportStatus.find(
            //    (s) => s.id == i["Report__relation"]["Status"],
            // )?.name;
            // i.isEditable = i["Report__relation"]["Status"] === "1610069614034" || i["Report__relation"]["Status"] === "1611822464215";
            data.isEditable = this.isStatusEditable(
               data["Report__relation"]["Status"],
            );
            data.status = this.getStatus(data["Report__relation"]["Status"]);
         }
         if (data["Date"] && groupByDates) {
            data.receiptPhotosCount =
               receiptDC.getData(
                  (receipt) =>
                     receipt.data.ReportItem914__relation.find(
                        (reportItem) => reportItem.id === data.id,
                     ) != null,
               ).length ?? 0;
            expenseHash.push({
               date: moment(data["Date"]).format("YYYYMMDD"),
               isConfirmed: i.isConfirmed,
               data,
            });
         }
      });
      if (!groupByDates) return expenseItems;
      var grouped = _.mapValues(_.groupBy(expenseHash, "date"), (clist) =>
         clist.map((car) => _.omit(car, "date")),
      );

      Object.entries(grouped).forEach(([key, values]) => {
         expensesFormatted.push({
            expenseItem: values, // this is what the sub report iterates through! very important
            date: {
               day: moment(key, "YYYYMMDD").format("D"),
               month: moment(key, "YYYYMMDD").format("MMM"),
            },
         });
      });

      // passed firstDay is the first day of the current month reverse the expense order
      var date = new Date();
      if (
         dateFilter &&
         firstDay.getTime() ==
            new Date(date.getFullYear(), date.getMonth(), 1).getTime()
      ) {
         expensesFormatted = expensesFormatted.reverse();
      }

      // dates is a special array of all used dates to be used in timeline
      // Foreach Date
      // // each report
      expensesObj = {
         dates: expensesFormatted,
         // total: totalExpenses.toFixed(2),
         symbol: "",
      };

      // console.log({ "Formatted expense items object:": expensesObj });

      return expensesObj;
   }

   // Check whether the given status ID is editable
   // [x] reports [ ] donations
   // params: string
   // output: boolean
   isStatusEditable(Status) {
      const VALID_REPORT_STATUSES = [
         "1610069614203",
         "1610069614093",
         "1612340065524",
         "1612340065678",
         "Received",
      ];
      const EDITABLE_REPORT_STATUSES = [
         "1610069614034", // Draft
         "1611822464215", // Rejected
      ];
      // TODO update this to have consistent logic
      // if not in valid status, return true
      return !VALID_REPORT_STATUSES.includes(Status);
   }

   /**
    * get the status of a report
    * @param {string} Status
    * @returns {string} status
    */
   getStatus(Status) {
      // TODO replace this with accessing the data collection later
      const REPORT_STATUSES = {
         1610069614203: "Submitted",
         1610069614093: "Submitted",
         1612340065524: "Submitted",
         1612340065678: "Submitted",
         Received: "Received",
         1610069614034: "Draft",
         1611822464215: "Rejected",
      };
      return REPORT_STATUSES[Status] || "Rejected";
   }

   /**
    *  smart select required selection validate
    *
    * @param {object} smartSelect
    *   the smart select object
    *
    */
   smartSelectRequired(field) {
      // get the select html child element
      var userInput = $(field).find("select").get(0).value;

      function addCustomErrorElement(message) {
         // the default error message cannot be made to with with smart-select
         // so we add a custom error message element
         // select-input-error-message defaults to display none
         var errorElement = $(field)
            .parent()
            .find(".select-input-error-message");
         // financialApp.smartSelectRequired("test")
         if (message === "") {
            errorElement.hide();
         } else {
            errorElement.show();
         }
      }

      let noSelection = userInput === "";
      if (noSelection) {
         addCustomErrorElement("Please select an item in the list.");
         return false;
      } else {
         addCustomErrorElement("");
         return true;
      }
   }

   /**
    * take in id of parent form,
    * Look for any required child smart selects
    * if any are empty, update them to have an error message
    * return false if any are empty
    * @param {*} field
    * @returns
    */
   smartSelectValidate(input) {
      //
      var form = $(input).get(0);
      var smartSelects = $(form).find(".smart-select");
      var smartSelectsValid = true;
      smartSelects.each((i, field) => {
         if (field.required) {
            smartSelectsValid =
               this.smartSelectRequired(field) && smartSelectsValid;
         }
      });
      return smartSelectsValid;
   }

   /**
    * Validate an amount
    * it should be a currency number
    * It should be less than 1000000000 (warn developer if this is not the case)
    * @param {*} amount
    * @returns
    */
   amountValidate(amount) {
      amount = Number(amount);
      if (isNaN(amount)) {
         return 0;
      } else {
         // report to countly
         if (amount > 100000000) {
            console.error("Amount is very large...", amount);
            // TODO how do we report this to countly?
         }
         // If amount is a whole number, don't add decimals
         // to fixed returns a string, so we need to convert it back to a number
         amount = Number.isInteger(amount) ? amount : Number(amount.toFixed(2));

         return amount;
      }
   }

   /**
    * set color blue of passed button and enable it
    */
   enable(button) {
      button.css("background-color", "var(--f7-color-blue)");
      button.css("color", "var(--f7-color-white)");
      button.attr("disabled", false);
   }
   /**
    * set color grey passed button and disable it
    */
   disable(button) {
      button["disabled"] = true;
      button.attr("disabled", true);
      button.css("background-color", "var(--f7-color-gray)");
      button.css("color", "var(--f7-color-black)");
   }

   get dcKeys() {
      return structuredClone(this._dcKeys);
   }

   findMax(curr, name, prev) {
      if (curr.data["Project Name"] != name || !curr.data["Fiscal Year"]) {
         return prev;
      }

      if (!prev) {
         return curr;
      }

      let currYR = parseInt(
         (curr.data["Fiscal Year"] || "").match(numberPattern)[0],
      );
      let prevYR = parseInt(
         (prev.data["Fiscal Year"] || "").match(numberPattern)[0],
      );

      if (prevYR > currYR) {
         return prev;
      } else {
         return curr;
      }
   }

   /**
    * @param { string*} chosenRCName { bool } isPowerUser
    * @returns array
    */
   projectsByRC(chosenRCName, isPowerUser = false) {
      let newProjects = [];
      let maxUB = null;
      let maxNA = null;

      let listAllProjects = this.dataCollection(
         this.PRJ_DC(isPowerUser),
      ).getData(() => true);

      listAllProjects.forEach((p) => {
         maxUB = this.findMax(p, "UB", maxUB);
         maxNA = this.findMax(p, "NA", maxNA);
         if (p.data.RC === chosenRCName) {
            newProjects.push(p);
         }
      });

      if (maxUB) {
         newProjects.unshift(maxUB);
      }
      if (maxNA) {
         newProjects.unshift(maxNA);
      }

      return newProjects;
   }
}

export default new Financial();
