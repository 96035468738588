/**
 * @class Inbox
 */
"use strict";

import Common from "./classes/Common";

const FEEDBACK_TIMEOUT = 15000;
class Feedback extends Common {
   /**
    */
   constructor() {
      super([
         {
            path: "/feedback/",
            popup: {
               componentUrl: "./lib/platform/pages/components/feedback.html",
            },
         },
      ]);

      // These properties are kept in storage between sessions
      this.note = "";
      this.photoFilename = null;
      this.photoURL = null;
      this.objectID = "281b5672-bc04-4463-b22e-de3494d872a7";
      this.imageFieldID = "59d65c3c-c62b-4c3f-865b-ce79b280c6d7";
      this.on("feedback-photo-upload", () => {});
      // network.on("feedback-photo-upload", (context, data) => {
      //    Promise.resolve()
      //       .then(() => {
      //          var updatedFeedback = {
      //             uuid: context.uuid,
      //             Screenshot: data.uuid,
      //          };

      //          this.object("Feedback")
      //             .model()
      //             .update(updatedFeedback.uuid, updatedFeedback);
      //       })
      //       .catch((err) => {
      //          this.page.f7App.dialog.alert(err.message || err, "<t>Error</t>");
      //       });
      // });
   }

   /**
    * init()
    * called by the platform (app.js) after the Storage mechanism is
    * already initialized and ready to go.
    */
   async init(page) {
      await super.init(page);
      this.dc = this.page.app.abDCs.find(
         (abDC) => abDC.id === "Feedback" || abDC.name === "Feedback",
      );
      this.cachedState = {
         file: null,
      }
      // const page = this.page;
      const app = page.app;
      const resources = app.resources;
      const storage = resources.storage;
      const network = resources.network;

   }

   /**
    * Delete previous attached photo, if any.
    *
    * @return {Promise}
    */
   async deletePhoto() {
      let filename = this.photoFilename;
      this.photoFilename = null;
      this.photoURL = null;
      // await this.dc.setData()
      // await storage.set(this.dc.refStorage(), "feedback-data", {
      //    note: this.note,
      // });
      // return Promise.resolve()
      //    .then(() => {
      //       var filename = this.photoFilename;

      //       this.photoFilename = null;
      //       this.photoURL = null;

      //       storage.set("feedback-data", {
      //          note: this.note,
      //       });

      //       if (filename) {
      //          return camera.deletePhoto(filename);
      //       }
      //    })
      //    .catch((err) => {
      //       return null;
      //    });
   }

   /**
    * Get a new library photo from the user.
    * This loads new data into these object properties:
    *  - photoFilename
    *  - photoURL
    *
    * @return {Promise}
    */
   async getPhoto() {
      this.cachedState.file = await this.page.app.resources.camera.getPhoto(false);
   }

   /**
    * Submit the feedback to the server.
    *
    * @param {string} note
    *
    * @return {Promise}
    */
   async sendFeedback(note = "") {
      if (!note && !this.photoFilename) throw new Error("Nothing to send");

      const app = this.page.app;
      const page = this.page;
      // const author = page.components.profile.userProfile;
      const author = app.resources.account.userData?.user;
      const username = app.resources.account.userData?.user;
      // const username = page.components.profile.userProfile["System Access"];
      const file = this.cachedState.file;
      var fileid = null;
      if (file) {
         fileid = await this.page.app.resources.storage.uploadFile(
            this.objectID, // receiptObj.id,
            this.imageFieldID, // imageFieldID,
            {
               file,
               uploadedBy: username,
            },
         );
      }

      await new Promise((resolve, reject) => {
         const loadingPage = app.pages.loadingPage;
         let timeout = setTimeout(() => {
            timeout = false;
            loadingPage.hide();
            reject(new Error("Feedback timed out!"));
         }, FEEDBACK_TIMEOUT);

         if (author == null) {
            // TODO (Guy): Alert to the user.
            reject(new Error("No user profile!"));
         }

         const data = {
            // uuid: app.utils.uuidv4(),
            Description: note,
            userAgent: navigator.userAgent,
            packageInfoversion: "",
            packageInfodescription: "",
            packageInfolabel: "",
            packageInfodeploymentKey: "",
            route: "",
            history: "",
            "Submitted By": username,
            Source: "Mobile App",
            Timestamp: moment(new Date().toISOString()),
            // Screenshot: fileid.data.uuid,
         };
         if (fileid?.data?.uuid) {
            data["Screenshot"] = fileid.data.uuid;
         }
         const router = page.appView.router;
         data.route = router.previousRoute.path;
         data.history = router.history.join(" --> ");
         // data.packageInfoversion = info.version;
         // data.packageInfodescription = info.description;
         // data.packageInfolabel = info.label;
         // data.packageInfodeploymentKey = info.deploymentKey;
         (async () => {
            try {
               // loadingPage.overlay();
               await this.dc.setData(null, data);
            } catch (err) {
               // loadingPage.hide();
               reject(err);
            }
         })();
      });
   }
}

export default new Feedback();
